import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Inject, Injectable, signal} from '@angular/core'
import {IApplicationResult} from '@sparbanken-syd/loan-backend'
import {HelperService, TokenPayload} from '@sparbanken-syd/sparbanken-syd-bankid'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

export interface Payload extends TokenPayload {
  exp?: number
  admin?: boolean
}

export type UcMockType = 'ok' | 'ko'

@Injectable({
  providedIn: 'root'
})
export class LoanService {
  /**
   * Extra options for the http requests. Typically, the authorization header.
   */
  private httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'no-good'
    })
  }

  public isMockUcActive$ = signal<UcMockType | null>(null)

  constructor(
    private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  /**
   * Set the authentication token
   * @param token - The token as received from the login service
   */
  public setToken(token: string): void {
    const payload = HelperService.GetTokenPayload(token) as Payload
    const tokenTimeStamp = payload.exp
    const loginState = {
      token,
      timeStamp: tokenTimeStamp
    }
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', `Bearer ${loginState.token}`)
    this.injectedLocalStorage.setItem('accessToken', JSON.stringify(loginState))
  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken = (): void => {
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'no-good')
    this.injectedLocalStorage.removeItem('accessToken')
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): boolean {
    const loginStateString = this.injectedLocalStorage.getItem('accessToken')
    if (loginStateString) {
      const loginState = JSON.parse(loginStateString)
      if (HelperService.GetTokenPayload(loginState.token)) {
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', `Bearer ${loginState.token}`)
        return true
      }
    }
    this.resetToken()
    return false
  }

  /**
   * Create an application
   */
  public apply(application: any, soft = false): Observable<any> {
    const url = `${environment.loanServiceUrl}/promise/${soft ? 'submit' : 'apply'}`
    return this.httpClient.post<any>(url, application, this.httpOptions)
  }

  public getResult(id: string): Observable<IApplicationResult> {
    const url = `${environment.loanServiceUrl}/result/${id}`
    return this.httpClient.get<IApplicationResult>(url, this.httpOptions)
  }

  /**
   * Look for existing promise of this person
   * @param personNummer - The personnummer
   */
  public check(personNummer: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/promise/check/${personNummer}`
    return this.httpClient.get<any>(url, this.httpOptions)
  }

  public getDocumentLink(id: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/documents/${id}`
    return this.httpClient.get<any>(url, this.httpOptions)
  }
}
