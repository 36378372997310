import * as i0 from '@angular/core';
import { Injectable, Component, Input, Inject, InjectionToken, EventEmitter, Output, NgModule } from '@angular/core';
import { takeUntil, take, switchMap } from 'rxjs/operators';
import * as i2$1 from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subject, filter, tap, NEVER, catchError, timer, of } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import * as i3 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i1$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@sparbanken-syd/sparbanken-syd-qr';
import { QrModule } from '@sparbanken-syd/sparbanken-syd-qr';
import * as i3$1 from '@angular/material/progress-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/**
 * This is a "Class" I have a dream of making this
 * injectable, until then, it is a class
 */
function RunningComponent_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.statusText);
  }
}
function RunningComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "spb-qr", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("orderRef", ctx_r1.orderRef);
  }
}
const _c0 = () => ({
  status: "cancel"
});
function WaitDialogComponent_mat_dialog_actions_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-dialog-actions")(1, "button", 3);
    i0.ɵɵtext(2, "St\xE4ng");
    i0.ɵɵelementEnd()();
  }
}
function BankIdComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelement(1, "mat-spinner");
    i0.ɵɵelementEnd();
  }
}
function BankIdComponent_Conditional_1_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "div");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r2.errorMessage);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("(", ctx_r2.errorDetail, ")");
  }
}
function BankIdComponent_Conditional_1_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7)(1, "button", 8);
    i0.ɵɵlistener("click", function BankIdComponent_Conditional_1_Conditional_2_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r5.startBankId(false));
    });
    i0.ɵɵtext(2, " BankID p\xE5 denna enhet ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 8);
    i0.ɵɵlistener("click", function BankIdComponent_Conditional_1_Conditional_2_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r7 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r7.startBankId(true));
    });
    i0.ɵɵtext(4, " BankID p\xE5 annan enhet ");
    i0.ɵɵelementEnd()();
  }
}
function BankIdComponent_Conditional_1_Conditional_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h3");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "p");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r8.waitInfo.title);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r8.waitInfo.message);
  }
}
function BankIdComponent_Conditional_1_Conditional_3_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "spb-running", 10);
    i0.ɵɵelementStart(1, "button", 11);
    i0.ɵɵlistener("click", function BankIdComponent_Conditional_1_Conditional_3_Conditional_2_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r10 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r10.cancel());
    });
    i0.ɵɵtext(2, "Avbryt");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("other", ctx_r9.other)("orderRef", ctx_r9.orderRef)("statusText", ctx_r9.statusText);
  }
}
function BankIdComponent_Conditional_1_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtemplate(1, BankIdComponent_Conditional_1_Conditional_3_Conditional_1_Template, 4, 2)(2, BankIdComponent_Conditional_1_Conditional_3_Conditional_2_Template, 3, 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, ctx_r4.waitingForBankId ? 1 : 2);
  }
}
function BankIdComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, BankIdComponent_Conditional_1_Conditional_1_Template, 5, 2, "div", 3)(2, BankIdComponent_Conditional_1_Conditional_2_Template, 5, 0, "div", 4)(3, BankIdComponent_Conditional_1_Conditional_3_Template, 3, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, ctx_r1.errorMessage ? 1 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(2, ctx_r1.selectMode ? 2 : ctx_r1.skipDialogs ? 3 : -1);
  }
}
class Collector {
  constructor(bankIdService, orderRef, userRef) {
    this.bankIdService = bankIdService;
    this.orderRef = orderRef;
    this.userRef = userRef;
    this.userTexts = {
      userCancel: 'Avbrutet i BankID applikationen',
      outstandingTransaction: 'Försöker starta BankID-appen',
      userSign: 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera',
      invalidParameters: 'Något är fel',
      startFailed: 'Kunde inte starta BankID'
    };
    /**
     * The following codes cancels
     * @private
     */
    this.problematicCodes = ['invalidParameters', 'startFailed', 'userCancel'];
    /**
     * This is what we emit
     */
    this.result$ = new Subject();
    /**
     * Various status texts.
     */
    this.status$ = new Subject();
    /**
     * We can cancel or any one outside might cancel.
     * We do not care of the value but use boolean for fun.
     */
    this.cancel$ = new Subject();
    this.collect().subscribe();
  }
  /**
   * We return the collect response in case anyone
   * wants it and that is what naturally comes out
   * of this.
   */
  collect() {
    return timer(0, 2000).pipe(takeUntil(this.cancel$), take(60), switchMap(() => this.bankIdService.collect(this.orderRef, this.userRef)), filter(Boolean), tap(response => {
      /**
       * The code can be error or hint pick one, prefer hint
       */
      const code = response.hintCode || response.errorCode;
      this.status$.next(this.userTexts[code]);
      if (this.problematicCodes.indexOf(code) !== -1) {
        // Should we call cancel if user cancel?
        this.bankIdService.cancel(this.orderRef).subscribe();
        this.status$.next(this.userTexts[response.hintCode]);
        this.done({
          status: 'cancel'
        }); // Or error?
      }
      if (response.accessToken) {
        this.status$.complete();
        this.done({
          status: 'ok',
          accessToken: response.accessToken
        });
      }
    }), catchError(error => {
      this.done({
        status: 'error',
        errorMessage: error.message,
        errorDetail: error.error?.errorMessage
      });
      return NEVER;
    }));
  }
  done(result) {
    this.cancel$.next(true);
    this.result$.next(result);
    // Make sure the statuses stop emitting
    this.result$.complete();
    this.status$.complete();
  }
}
class BankIdService {
  /**
   * We need a client
   */
  constructor(httpClient) {
    this.httpClient = httpClient;
    /**
     * Has to be set from the outside, this is where start (sign or auth)
     * goes to. All other calls will go on collect URL
     */
    this.bankIdUrl = '';
  }
  /**
   * Start auth session.
   */
  startLogin() {
    let headers = new HttpHeaders();
    headers = headers.set('X-SSO-SITE', 'sso-site');
    const url = `${this.bankIdUrl}/login/start`;
    const data = {
      domain: this.bankIdUrl,
      type: 'auth',
      scope: 'auth'
    };
    return this.httpClient.put(url, data, {
      headers
    });
  }
  /**
   * Start sign session
   * @param userRef
   */
  startSign(userRef) {
    const url = `${this.bankIdUrl}/login/start/?userRef=${userRef}`;
    return this.httpClient.get(url);
  }
  /**
   * Collect, typically every two seconds.
   *
   * @param orderRef - The BankID order reference
   * @param userRef - A reference used for a signing case
   */
  collect(orderRef, userRef) {
    const url = `${this.collectUrl ?? this.bankIdUrl}/login/collect?orderRef=${orderRef}`;
    //Sign
    if (userRef) {
      return this.httpClient.get(`${url}&userRef=${userRef}`);
    } else {
      //Login
      let headers = new HttpHeaders();
      headers = headers.set('X-SSO-SITE', 'sso-site');
      return this.httpClient.get(url, {
        headers,
        withCredentials: true
      });
    }
  }
  /**
   * Cancel the request
   */
  cancel(orderRef) {
    const url = `${this.collectUrl ?? this.bankIdUrl}/login/cancel?orderRef=${orderRef}`;
    return this.httpClient.get(url);
  }
  /**
   * Get image, which represents a qrAuthCode, from database
   */
  getImage(orderRef) {
    const url = `${this.collectUrl ?? this.bankIdUrl}/login/image?orderRef=${orderRef}`;
    return this.httpClient.get(url);
  }
  static {
    this.ɵfac = function BankIdService_Factory(t) {
      return new (t || BankIdService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BankIdService,
      factory: BankIdService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BankIdService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class QrCodeComponent {
  constructor(bankIdService) {
    this.bankIdService = bankIdService;
    /**
     * The data we need for calling get image
     */
    this.orderRef = '';
    /**
     * Data is what we send to the QR code component
     */
    this.data = '';
    /**
     * Used to cancel timer
     */
    this.cancel = new Subject();
  }
  ngAfterViewInit() {
    timer(0, 1000)
    /**
     * takeUntil the subject emits...
     */.pipe(takeUntil(this.cancel), switchMap(() => this.bankIdService.getImage(this.orderRef))).subscribe({
      next: qrAuthCode => this.data = qrAuthCode
    });
  }
  ngOnDestroy() {
    this.cancel.next(null);
  }
  static {
    this.ɵfac = function QrCodeComponent_Factory(t) {
      return new (t || QrCodeComponent)(i0.ɵɵdirectiveInject(BankIdService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: QrCodeComponent,
      selectors: [["spb-qr"]],
      inputs: {
        orderRef: "orderRef"
      },
      decls: 10,
      vars: 1,
      consts: [[3, "data"]],
      template: function QrCodeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "ol")(1, "li");
          i0.ɵɵtext(2, "\xD6ppna appen f\xF6r BankID.");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "li");
          i0.ɵɵtext(4, "Tryck p\xE5 QR-kod/Skanna QR kod i appen f\xF6r BankID.");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "li");
          i0.ɵɵtext(6, "Rikta kameran mot QR-koden.");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "li");
          i0.ɵɵtext(8, "F\xF6lj instruktionerna i appen.");
          i0.ɵɵelementEnd()();
          i0.ɵɵelement(9, "spb-qr-code", 0);
        }
        if (rf & 2) {
          i0.ɵɵadvance(9);
          i0.ɵɵproperty("data", ctx.data);
        }
      },
      dependencies: [i2.QRComponent],
      styles: ["ol[_ngcontent-%COMP%]{margin:0 0 28px;text-align:left}li[_ngcontent-%COMP%]{font-size:1.3rem;line-height:1.6rem;margin-bottom:8px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QrCodeComponent, [{
    type: Component,
    args: [{
      selector: 'spb-qr',
      template: "<ol>\n  <li>\u00D6ppna appen f\u00F6r BankID.</li>\n  <li>Tryck p\u00E5 QR-kod/Skanna QR kod i appen f\u00F6r BankID.</li>\n  <li>Rikta kameran mot QR-koden.</li>\n  <li>F\u00F6lj instruktionerna i appen.</li>\n</ol>\n<spb-qr-code [data]=\"data\"></spb-qr-code>\n",
      styles: ["ol{margin:0 0 28px;text-align:left}li{font-size:1.3rem;line-height:1.6rem;margin-bottom:8px}\n"]
    }]
  }], () => [{
    type: BankIdService
  }], {
    orderRef: [{
      type: Input
    }]
  });
})();
class RunningComponent {
  constructor() {
    this.other = false;
    this.orderRef = '';
    this.statusText = '';
  }
  static {
    this.ɵfac = function RunningComponent_Factory(t) {
      return new (t || RunningComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: RunningComponent,
      selectors: [["spb-running"]],
      inputs: {
        other: "other",
        orderRef: "orderRef",
        statusText: "statusText"
      },
      decls: 2,
      vars: 2,
      consts: [[4, "ngIf"], [3, "orderRef"]],
      template: function RunningComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, RunningComponent_span_0_Template, 2, 1, "span", 0)(1, RunningComponent_div_1_Template, 2, 1, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !ctx.other);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.other);
        }
      },
      dependencies: [i1$1.NgIf, QrCodeComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RunningComponent, [{
    type: Component,
    args: [{
      selector: 'spb-running',
      template: "<span *ngIf=\"!other\">{{ statusText }}</span>\n<div *ngIf=\"other\">\n  <spb-qr [orderRef]=\"orderRef\"></spb-qr>\n</div>"
    }]
  }], null, {
    other: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    orderRef: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    statusText: [{
      type: Input
    }]
  });
})();
class SignDialogComponent {
  constructor(bankIdService, dialogRef, data) {
    this.bankIdService = bankIdService;
    this.dialogRef = dialogRef;
    this.data = data;
    /**
     * Temp for test
     */
    this.statusText = '';
    /**
     * Other device, that is show QR
     */
    this.other = false;
    this.orderRef = '';
    this.cancel$ = new Subject();
  }
  ngOnInit() {
    this.other = this.data.other;
    this.orderRef = this.data.orderRef;
    const collector = new Collector(this.bankIdService, this.orderRef, this.data.userRef);
    collector.result$.subscribe({
      next: res => {
        this.dialogRef.close(res);
      }
    });
    collector.status$.subscribe({
      next: message => this.statusText = message
    });
    this.cancel$ = collector.cancel$;
  }
  /**
   * The collector will stop after user cancel is received
   */
  cancel() {
    this.bankIdService.cancel(this.orderRef);
    this.dialogRef.close({
      status: 'cancel'
    });
    this.cancel$.next(true);
  }
  static {
    this.ɵfac = function SignDialogComponent_Factory(t) {
      return new (t || SignDialogComponent)(i0.ɵɵdirectiveInject(BankIdService), i0.ɵɵdirectiveInject(i2$1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SignDialogComponent,
      selectors: [["spb-sign-dialog"]],
      decls: 7,
      vars: 8,
      consts: [["matDialogTitle", ""], ["matDialogContent", ""], [3, "orderRef", "other", "statusText"], ["mat-stroked-button", "", 3, "matDialogClose", "click"]],
      template: function SignDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵelement(3, "spb-running", 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "mat-dialog-actions")(5, "button", 3);
          i0.ɵɵlistener("click", function SignDialogComponent_Template_button_click_5_listener() {
            return ctx.cancel();
          });
          i0.ɵɵtext(6, "Avbryt ");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.data.title);
          i0.ɵɵadvance();
          i0.ɵɵstyleMap("padding: 20px 24px 20px 24px");
          i0.ɵɵadvance();
          i0.ɵɵproperty("orderRef", ctx.orderRef)("other", ctx.other)("statusText", ctx.statusText);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("matDialogClose", i0.ɵɵpureFunction0(7, _c0));
        }
      },
      dependencies: [i2$1.MatDialogClose, i2$1.MatDialogTitle, i2$1.MatDialogActions, i2$1.MatDialogContent, i3.MatButton, RunningComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignDialogComponent, [{
    type: Component,
    args: [{
      selector: 'spb-sign-dialog',
      template: "<div matDialogTitle>{{data.title}}</div>\n<div matDialogContent [style]=\"'padding: 20px 24px 20px 24px'\">\n  <spb-running\n    [orderRef]=\"orderRef\"\n    [other]=\"other\"\n    [statusText]=\"statusText\"\n  ></spb-running>\n</div>\n<mat-dialog-actions>\n  <button (click)=\"cancel()\"\n          [matDialogClose]=\"{status: 'cancel'}\"\n          mat-stroked-button\n  >Avbryt\n  </button>\n</mat-dialog-actions>\n"
    }]
  }], () => [{
    type: BankIdService
  }, {
    type: i2$1.MatDialogRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DIALOG_DATA]
    }]
  }], null);
})();
class WaitDialogComponent {
  constructor(data) {
    this.data = data;
  }
  static {
    this.ɵfac = function WaitDialogComponent_Factory(t) {
      return new (t || WaitDialogComponent)(i0.ɵɵdirectiveInject(MAT_DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WaitDialogComponent,
      selectors: [["spb-wait-dialog"]],
      decls: 5,
      vars: 3,
      consts: [["matDialogTitle", ""], ["matDialogContent", ""], [4, "ngIf"], ["color", "primary", "mat-raised-button", ""]],
      template: function WaitDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, WaitDialogComponent_mat_dialog_actions_4_Template, 3, 0, "mat-dialog-actions", 2);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.data.title);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", ctx.data.message, " ");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.data.close);
        }
      },
      dependencies: [i1$1.NgIf, i2$1.MatDialogTitle, i2$1.MatDialogActions, i2$1.MatDialogContent, i3.MatButton],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WaitDialogComponent, [{
    type: Component,
    args: [{
      selector: 'spb-wait-dialog',
      template: `
    <div matDialogTitle>{{data.title}}</div>
    <div matDialogContent>
      {{data.message}}
    </div>
    <mat-dialog-actions *ngIf="data.close">
      <button color="primary" mat-raised-button>Stäng</button>
    </mat-dialog-actions>
  `
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DIALOG_DATA]
    }]
  }], null);
})();

/**
 * this is the Angular 6 way of getting access to the window.
 *
 * Admittedly, I do not understand shit of this!
 *
 * However since we use "window" to change the location and
 * we to be able to mock it so that it does not actually navigate
 * in code when called we need to "inject" window into the application.
 *
 */
/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
const WINDOW = new InjectionToken('window');
/**
 * The provider
 */
const windowProvider = {
  provide: WINDOW,
  useFactory: () => window
};
/**
 * The exported provider(s). We only have one. This is "provided"
 * in app.component.
 */
const WINDOW_PROVIDERS = [windowProvider];

/**
 * Calls BankID as necessary and when done (or failed)
 * it emits an event that (someone) can listen to.
 */
class BankIdComponent {
  /**
   * Default constructor
   */
  constructor(bankIdService, dialog, window) {
    this.bankIdService = bankIdService;
    this.dialog = dialog;
    this.window = window;
    /**
     * The access token after successful authentication.
     * It is a base64 encoded jwt token for your pleasure
     */
    this.accessToken = new EventEmitter();
    /**
     * The complete or relative url to use. If nothing set '/service' is assumed.
     */
    this.bankIdUrl = '';
    /**
     * The domain from which the user is directed
     */
    this.domain = '';
    /**
     * The user ref and the backend which is used for signing (for example blanco backend)
     */
    this.userRef = '';
    /**
     * If you for some reason, cannot and do not, want
     * to open dialogs, set this to true.
     */
    this.skipDialogs = false;
    /**
     * Used by template to hide or show buttons
     * if we are not in Dialog mode.
     */
    this.selectMode = true;
    /**
     * If other device we set this.
     */
    this.other = false;
    /**
     * We set this after start.
     */
    this.orderRef = '';
    this.waitingForBankId = false;
    /**
     * A spinner that is displayed if the user has received an OK from bankID
     */
    this.spinner = false;
    /**
     * Used in dialog or as message in template.
     */
    this.waitInfo = {
      title: 'Förbereder BankID',
      message: 'Vi förbereder för BankID, var god dröj...'
    };
    /**
     * Default "waitRef", replaced with dialog if needed
     */
    this.waitRef = {
      close: () => this.waitingForBankId = false
    };
    /**
     * This will be replaced with the collector if we run
     * in headless mode (no dialogs).
     */
    this.cancel$ = new Subject();
  }
  /**
   * Set wait state and try to log in.
   *
   * @param other - 'other' is login on 'other device', id est start here or show qr.
   */
  startBankId(other) {
    // If no dialogs we switch to waiting
    this.waitingForBankId = this.skipDialogs;
    this.other = other;
    this.bankIdService.bankIdUrl = this.bankIdUrl;
    this.bankIdService.collectUrl = this.collectUrl;
    this.openDialog();
  }
  openDialog() {
    this.errorMessage = undefined;
    this.waitFn().pipe(switchMap(() => this.startBankIdFn()), switchMap(() => {
      this.waitRef.close();
      if (this.skipDialogs) {
        return this.runInline();
      }
      return this.runInDialog();
    })).subscribe({
      next: res => {
        if (res.status === 'bankIDTimeout') {
          this.errorMessage = 'Tidsgränsen tog slut, vänligen försök igen';
        }
        if (res.accessToken) {
          this.spinner = true;
          this.accessToken.emit(res.accessToken);
        }
        if (res.status === 'error') {
          this.errorMessage = 'Kunde inte starta BankID';
          this.errorDetail = res.errorDetail;
        }
        if (res.status === 'cancel') {
          this.selectMode = true;
          this.errorMessage = 'Kunde inte starta BankID';
          this.errorDetail = res.errorDetail;
        }
      },
      error: err => {
        this.waitRef.close();
        this.errorMessage = 'Kunde inte starta BankID';
        this.errorDetail = err.error.errorMessage;
      }
    });
  }
  cancel() {
    this.cancel$.next(true);
    // Switch back to select this device or other device?
    this.selectMode = true;
  }
  runInDialog() {
    const signDialogRef = this.dialog.open(SignDialogComponent, {
      width: '90%',
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: this.userRef ? 'Underskrift' : 'Legitimera',
        other: this.other,
        userRef: this.userRef,
        orderRef: this.orderRef
      }
    });
    // afterClosed is said to be able to return undefined, so we assert
    return signDialogRef.afterClosed();
  }
  runInline() {
    const collector = new Collector(this.bankIdService, this.orderRef, this.userRef);
    collector.status$.subscribe(t => this.statusText = t);
    this.cancel$ = collector.cancel$;
    return collector.result$;
  }
  startBankIdFn() {
    let obs;
    if (this.userRef) {
      obs = this.bankIdService.startSign(this.userRef);
    } else {
      obs = this.bankIdService.startLogin();
    }
    this.selectMode = false;
    return obs.pipe(tap(r => {
      this.orderRef = r.orderRef;
      if (!this.other) {
        const loginOnThisDeviceLink = `bankid:///?autostarttoken=${r.autoStartToken}&redirect=null`;
        /**
         * We should figure out a way to detect failures...
         */
        this.window.open(loginOnThisDeviceLink, '_parent');
      }
    }));
  }
  waitFn() {
    if (this.skipDialogs) {
      this.waitingForBankId = true;
      // Must return something, empty of() does nothing
      return of(true);
    }
    // When dialogs we use a dialog.
    const waitRef = this.dialog.open(WaitDialogComponent, {
      disableClose: true,
      data: this.waitInfo
    });
    this.waitRef = waitRef;
    return waitRef.afterOpened();
  }
  static {
    this.ɵfac = function BankIdComponent_Factory(t) {
      return new (t || BankIdComponent)(i0.ɵɵdirectiveInject(BankIdService), i0.ɵɵdirectiveInject(i2$1.MatDialog), i0.ɵɵdirectiveInject(WINDOW));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BankIdComponent,
      selectors: [["spb-bankid"]],
      inputs: {
        bankIdUrl: "bankIdUrl",
        collectUrl: "collectUrl",
        domain: "domain",
        userRef: "userRef",
        skipDialogs: "skipDialogs"
      },
      outputs: {
        accessToken: "accessToken"
      },
      decls: 2,
      vars: 1,
      consts: [["class", "mat-spinner"], [1, "mat-spinner"], [1, "holder"], ["class", "spb-error"], ["class", "buttons"], [1, "spb-error"], [1, "error-detail"], [1, "buttons"], ["color", "primary", "mat-raised-button", "", 3, "click"], [1, "running"], [3, "other", "orderRef", "statusText"], ["mat-stroked-button", "", 3, "click"]],
      template: function BankIdComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, BankIdComponent_Conditional_0_Template, 2, 0, "div", 0)(1, BankIdComponent_Conditional_1_Template, 4, 2);
        }
        if (rf & 2) {
          i0.ɵɵconditional(0, ctx.spinner ? 0 : 1);
        }
      },
      dependencies: [i3$1.MatProgressSpinner, i3.MatButton, RunningComponent],
      styles: [".holder[_ngcontent-%COMP%]{display:flex;flex-direction:column;justify-content:center;align-items:center}.buttons[_ngcontent-%COMP%]{justify-content:center;display:flex}.buttons[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{margin:0 10px}.mat-spinner[_ngcontent-%COMP%]{display:flex;justify-content:center}.running[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:center;max-width:300px;padding:20px}.running[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{margin-top:20px}.error-message[_ngcontent-%COMP%]{color:#be0005;margin:20px 0 0;text-align:center}.spb-error[_ngcontent-%COMP%]{background-color:#be0005;color:#f3f2f1;font-weight:700;margin:1em auto;padding:.7em;text-align:center;width:90%}@media only screen and (max-width: 500px){.buttons[_ngcontent-%COMP%]{align-items:center;display:flex;flex-direction:column;justify-content:center}.buttons[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{margin:10px 0}}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BankIdComponent, [{
    type: Component,
    args: [{
      selector: 'spb-bankid',
      template: "@if (spinner) {\n  <div class=\"mat-spinner\">\n    <mat-spinner></mat-spinner>\n  </div>\n} @else {\n  <div class=\"holder\">\n    @if (errorMessage) {\n      <div class=\"spb-error\">\n        <div>{{ errorMessage }}</div>\n        <div class=\"error-detail\">({{ errorDetail }})</div>\n      </div>\n    }\n    @if (selectMode) {\n      <div class=\"buttons\">\n        <button\n          (click)=\"startBankId(false)\" color=\"primary\"\n          mat-raised-button>\n          BankID p\u00E5 denna enhet\n        </button>\n        <button\n          (click)=\"startBankId(true)\" color=\"primary\"\n          mat-raised-button>\n          BankID p\u00E5 annan enhet\n        </button>\n      </div>\n    } @else if (skipDialogs) {\n      <div class=\"running\">\n        @if (waitingForBankId) {\n          <h3>{{ waitInfo.title }}</h3>\n          <p>{{ waitInfo.message }}</p>\n        } @else {\n          <spb-running\n            [other]=\"other\"\n            [orderRef]=\"orderRef\"\n            [statusText]=\"statusText\"\n          >\n          </spb-running>\n          <button mat-stroked-button (click)=\"cancel()\">Avbryt</button>\n        }\n      </div>\n    }\n  </div>\n}\n\n",
      styles: [".holder{display:flex;flex-direction:column;justify-content:center;align-items:center}.buttons{justify-content:center;display:flex}.buttons button{margin:0 10px}.mat-spinner{display:flex;justify-content:center}.running{display:flex;flex-direction:column;align-items:center;max-width:300px;padding:20px}.running button{margin-top:20px}.error-message{color:#be0005;margin:20px 0 0;text-align:center}.spb-error{background-color:#be0005;color:#f3f2f1;font-weight:700;margin:1em auto;padding:.7em;text-align:center;width:90%}@media only screen and (max-width: 500px){.buttons{align-items:center;display:flex;flex-direction:column;justify-content:center}.buttons button{margin:10px 0}}\n"]
    }]
  }], () => [{
    type: BankIdService
  }, {
    type: i2$1.MatDialog
  }, {
    type: Window,
    decorators: [{
      type: Inject,
      args: [WINDOW]
    }]
  }], {
    accessToken: [{
      type: Output
    }],
    bankIdUrl: [{
      type: Input
    }],
    collectUrl: [{
      type: Input
    }],
    domain: [{
      type: Input
    }],
    userRef: [{
      type: Input
    }],
    skipDialogs: [{
      type: Input
    }]
  });
})();
class SingleSignOnService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  /**
   * Get cookie token from the database, based on cookie in header
   */
  getToken(bankidUrl, domain) {
    let headers = new HttpHeaders();
    headers = headers.set('X-SPB-SSO-DOMAIN', domain);
    const url = `${bankidUrl}/token`;
    return this.httpClient.get(url, {
      headers,
      withCredentials: true
    });
  }
  /**
   * Delete cookie token from the database, based on cookie in header
   */
  deleteToken(bankidUrl) {
    const url = `${bankidUrl}/token`;
    return this.httpClient.delete(url, {
      withCredentials: true
    });
  }
  static {
    this.ɵfac = function SingleSignOnService_Factory(t) {
      return new (t || SingleSignOnService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SingleSignOnService,
      factory: SingleSignOnService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SingleSignOnService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();

/**
 * A couple of helper functions to validate the a JWT and a function
 * that returns the current user.
 */
class HelperService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  /**
   * Returns the saem toke that is passed in, or null.
   *
   * @param token - A JWT token
   * @returns - The valid token or null
   */
  static ValidateToken(token) {
    if (!token) {
      return null;
    }
    try {
      const payload = JSON.parse(window.atob(token.split('.')[1]));
      return payload.exp > new Date().getTime() ? token : null;
    } catch (_e) {
      /**
       * Catch and ignore any problems with malformed tokens
       */
      return null;
    }
  }
  /**
   * Gets the payload from a JWT, it first validates it (the timestamp, not the signature)
   * before returning the payload as an object
   * @param token - The complete token, base64 encoded (a.ey.b)
   *
   * @constructor
   */
  static GetTokenPayload(token) {
    const verified = HelperService.ValidateToken(token);
    return verified ? JSON.parse(window.atob(verified.split('.')[1])) : null;
  }
  /**
   * Fetch the current user
   *
   * @param baseUrl - The URL including https:// etc where the user service is located
   */
  getCurrentUser(baseUrl) {
    const url = `${baseUrl}/users/self`;
    return this.httpClient.get(url);
  }
  static {
    this.ɵfac = function HelperService_Factory(t) {
      return new (t || HelperService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HelperService,
      factory: HelperService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HelperService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class BankIdModule {
  static {
    this.ɵfac = function BankIdModule_Factory(t) {
      return new (t || BankIdModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BankIdModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [WINDOW_PROVIDERS],
      imports: [CommonModule, MatProgressSpinnerModule, MatDialogModule, MatButtonModule, QrModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BankIdModule, [{
    type: NgModule,
    args: [{
      declarations: [BankIdComponent, WaitDialogComponent, SignDialogComponent, QrCodeComponent, RunningComponent],
      imports: [CommonModule, MatProgressSpinnerModule, MatDialogModule, MatButtonModule, QrModule],
      exports: [BankIdComponent],
      providers: [WINDOW_PROVIDERS]
    }]
  }], null, null);
})();

/*
 * Public API Surface of bankid
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BankIdComponent, BankIdModule, HelperService, SingleSignOnService };
